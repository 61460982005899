<template>
    <v-content>
        <v-container fluid>
            <!-- <v-row no-gutters>
                 <v-col cols="10"></v-col>
                 <v-col cols="2">
                     <v-btn cols="2" class="success" @click.stop="onDownload">
                         <v-icon>get_app</v-icon>
                         Export
                     </v-btn>
                 </v-col>
             </v-row>-->
            <v-row>
                <v-col>
                    <v-expansion-panels multiple hover>
                        <v-expansion-panel>
                            <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container fluid>
                                    <v-row col="12">
                                        <v-row align="start" justify="start">


                                            <JsonExcelVue :fetch="fetchDataPrivate" is-custom-header is-custom-data :customHeader="customHeader">
                                                <v-btn small class="success mx-4">
                                                    <v-icon>get_app</v-icon>
                                                    {{$t('download_to_excel')}}
                                                </v-btn>

                                            </JsonExcelVue>


                                            <!--<v-btn @click.stop="onDownloadPrivate" small class="success mx-4">
                                                <v-icon>get_app</v-icon>
                                                {{$t('download_to_excel')}}
                                            </v-btn>-->

                                        </v-row>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <table class="table tbl">
                        <thead>
                        <th> {{$t('private_institute')}}</th>
                        <th> {{$t('course')}} </th>
                        </thead>
                        <tbody>
                            <template v-for="row in privateRows">
                                <tr :key="row">
                                    <td :rowspan="row.data.length+1">{{row.instituteName}}</td>
                                </tr>
                                <template v-for="tableData in row.data">
                                    <tr :key="tableData">
                                        <td>
                                            {{tableData.course}}
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </v-col>

                <v-col>
                    <v-expansion-panels multiple hover>
                        <v-expansion-panel>
                            <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container fluid>
                                    <v-row col="12">
                                        <v-row align="start" justify="start">


                                            <JsonExcelVue :fetch="fetchDataPublic" is-custom-header is-custom-data :customHeader="customHeader">
                                                <v-btn small class="success mx-4">
                                                    <v-icon>get_app</v-icon>
                                                    {{$t('download_to_excel')}}
                                                </v-btn>

                                            </JsonExcelVue>

                                            <!--<v-btn @click.stop="onDownload" small class="success mx-4">
                                                <v-icon>get_app</v-icon>
                                                {{$t('download_to_excel')}}
                                            </v-btn>-->

                                        </v-row>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <table class="table tbl">
                        <thead>
                        <th> {{$t('public_institute')}}</th>
                        <th> {{$t('course')}} </th>
                        </thead>
                        <tbody>
                            <template v-for="row in publicRows">
                                <tr :key="row">
                                    <td :rowspan="row.data.length + 1">{{row.instituteName}}</td>
                                </tr>
                                <template v-for="tableData in row.data">
                                    <tr :key="tableData">
                                        <td>
                                            {{tableData.course}}
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </v-col>
            </v-row>



        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import JsonExcelVue from "@/components/ImportExport/JsonExcel.vue"

    export default {
        name: "MountaineeringHistoryList",
        props: [
            'moduleName'
        ],
        components: {
            JsonExcelVue
        },
        computed: {
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            },
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('academic_report'),
                        exact: true,
                        to: '/reportModules/reportAcademic'
                    },
                    {
                        text: this.$t('private_public_academic_report'),
                        exact: true,
                        disabled: true
                    }
                ]
            }
        },
        methods: {
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            async onDownload() {
                let param = {
                    ReportType: "Public",
                };
                await axios({
                    url: 'Academic/DownloadpublicPrivateInstituteReportListAsyc',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'publicInstituteReportListAsyc.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                });
                //await axios({
                //    url: 'Report/DownloadSample',
                //    method: 'POST',
                //    responseType: 'blob'
                //}).then((response) => {
                //    const url = window.URL.createObjectURL(new Blob([response.data]));
                //    const link = document.createElement('a');
                //    link.href = url;
                //    link.setAttribute('download', 'NationalParkFile.xlsx'); //or any other extension
                //    document.body.appendChild(link);
                //    link.click();
                //});
            },
            async onDownloadPrivate() {
                let param = {
                    ReportType: "Private",
                };
                await axios({
                    url: 'Academic/DownloadpublicPrivateInstituteReportListAsyc',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'PrivateInstituteReportListAsyc.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                });
            },
            fetchDataPrivate() {
                this.customHeader = [
                    {
                        name: "Private Institute"
                    },
                    {
                        name: "Course"
                    }
                ]
                const customData = []
                this.privateRows.map(res => {
                    let cData = []
                    cData.title = res.instituteName
                    cData.nestedData = res.data.map(res => {
                        return Object.values(res)
                    })
                    customData.push(cData)
                })
                return customData
            },
            fetchDataPublic() {
                this.customHeader = [
                    {
                        name: "Public Institute"
                    },
                    {
                        name: "Course"
                    }
                ]
                const customData = []
                this.publicRows.map(res => {
                    let cData = []
                    console.log('hi this is response', res)
                    cData.title = res.instituteName
                    cData.nestedData = res.data.map(res => {
                        return Object.values(res)
                    })
                    customData.push(cData)
                })
                return customData
            },
            async loadItems() {
                await axios.post('Academic/GetpublicPrivateInstituteReportListAsyc', { ReportType: "Public" })
                    .then(response => {
                        this.publicRows = response.data;
                        this.snackbar.loading = false;
                    })
                    .catch(err => {
                        console.log(err)
                    });
                await axios.post('Academic/GetpublicPrivateInstituteReportListAsyc', { ReportType: "Private" })
                    .then(response => {
                        this.privateRows = response.data;
                        this.snackbar.loading = false;
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },

        },
        mounted() {
            this.getNow()
            this.loadItems()
        },
        data() {
            return {
                customHeader: [],
                serverParams: {
                    ToDate: null,
                    FromDate: null,
                    ReportType: "yearwise"
                },
                snackbar: {
                    menu1: false
                },
                publicRows: [],
                privateRows: [],
            };
        },
    }
</script>

<style lang="scss" scoped>
    .v-sheet--offset {
        top: -24px;
        position: relative;
    }

    .tbl {

        th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #1e88e5;
            color: white;
        }
    }

    table, td, th {
        border: 1px solid #ddd;
        text-align: left;
    }

    table {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
    }

    th, td {
        padding: 15px;
    }

    .tfoot {
        background-color: #d2d2d2;
    }

    .data-input {
        .v-input {
            max-width: 7.1em !important;
        }
    }

    input {
        max-width: 5.7em !important;
    }

    .employee-form-header {
        background-color: #1e88e5;
        height: 3em;
        padding: .6em;

        span {
            color: #ffffff;
            font-size: 1.4em;
        }
    }

    #form_card {
        text-align: center;
    }

    .box-border {
        border: 1px solid;
        padding: 1em;
    }
</style>
<template>
    <v-content>
        <v-container fluid>
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row align="start" justify="center" class="mt-4">
                                <v-col class="d-flex" cols="4" sm="6">
                                    <v-autocomplete v-model="serverParams.countryName"
                                                    :items="CountryNames"
                                                    :label="$t('countryName')"
                                                    clearable
                                                    single-line
                                                    item-text="id"
                                                    dense
                                                    outlined
                                                    item-value="value"
                                                    @change="changeColumn"></v-autocomplete>
                                </v-col>

                                <v-col class="d-flex" cols="4" sm="6">
                                    <v-autocomplete v-model="serverParams.trekkingRoute"
                                                    :items="TrekkingRoutes"
                                                    :label="$t('trekkingRoute')"
                                                    clearable
                                                    single-line
                                                    item-text="id"
                                                    dense
                                                    outlined
                                                    item-value="value"
                                                    @change="changeColumn"></v-autocomplete>
                                </v-col>


                                <v-col class="d-flex" cols="3" sm="3">
                                    <v-autocomplete v-model="serverParams.gender"
                                                    :items="Gender"
                                                    :label="$t('gender')"
                                                    clearable
                                                    single-line
                                                    item-text="id"
                                                    dense
                                                    outlined
                                                    item-value="value"
                                                    @change="changeColumn"></v-autocomplete>
                                </v-col>

                                <v-col class="d-flex" cols="3" sm="3">
                                    <v-autocomplete v-model="serverParams.ageGroup"
                                                    :items="AgeGroup"
                                                    :label="$t('age_group')"
                                                    clearable
                                                    single-line
                                                    item-text="id"
                                                    dense
                                                    outlined
                                                    item-value="value"
                                                    @change="changeColumn"></v-autocomplete>
                                </v-col>



                                <v-col class="d-flex" cols="3" sm="3">
                                    <v-menu v-model="snackbar.menu1"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            :close-on-content-click="false"
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="serverParams.fromDate"
                                                          clearable
                                                          dense
                                                          outlined
                                                          prepend-inner-icon="event"
                                                          readonly
                                                          :label="$t('from_date')"
                                                          v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="serverParams.fromDate">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col class="d-flex" cols="3" sm="3">
                                    <v-menu v-model="snackbar.menu2"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            :close-on-content-click="false"
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="serverParams.toDate"
                                                          clearable
                                                          dense
                                                          outlined
                                                          prepend-inner-icon="event"
                                                          readonly
                                                          :label="$t('to_date')"
                                                          v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="serverParams.toDate">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3" justify="center">
                                <v-col class="d-flex" cols="12" sm="2">
                                    <v-spacer></v-spacer>
                                    <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                        <v-icon dark>search</v-icon>
                                        {{$t('search')}}
                                    </v-btn>
                                </v-col>
                                <v-col class="d-flex" cols="12" sm="2">
                                    <v-btn @click="onDownload" small class="success float-right mx-2">
                                        <v-icon>get_app</v-icon>
                                        {{$t('download_to_excel')}}
                                    </v-btn>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

        
            <vue-good-table mode="remote"
                             @on-sort-change="onSortChange"
                            @on-page-change="onPageChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :isLoading.sync="isLoading"
                            :pagination-options="{ enabled: true,mode: 'records' }"
                            :rows="rows"
                            :columns="columns"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'datedOn'">
                        <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
                    </span>
                </template>
            </vue-good-table>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    //import downloadExcel from "vue-json-excel";
    export default {
        name: "RestrictedAreasCountList",
        props: [
            'moduleName'
        ],
        computed: {
            //customYear() {
            //    let year = []
            //    for (let i = 0; i < 99; i++) {
            //        year.push(`20${i < 10 ? '0' : ''}${i}`)
            //    }
            //    return year
            //},
         
            //items() {
            //    return [
            //        {
            //            text: this.$t('reports'),
            //            exact: true,
            //            to: '/reportModules'
            //        },
            //        {
            //            text: this.$t('modules_name.trekking'),
            //            exact: true,
            //            to: '/reportModules/reportTrekking'
            //        },
            //        {
            //            text: this.$t('modules_name.monthly_yearly_report'),
            //            disabled: true,
            //            exact: true
            //        }
            //    ]
            //},
        },
        methods: {
            changeColumn(value) {
                console.log(value)
            },
            formResponse(data) {
                console.log({ data })
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            async onDownload() {

                this.excelData = {
                    loading: false,
                    json_fields: {},
                    json_data: [],
                    json_meta: [
                        [
                            {
                                key: "charset",
                                value: "utf-8",
                            },
                        ],
                    ],
                }
                let param = {}
                param = {
                    FromDate: this.serverParams.fromDate,
                    ToDate: this.serverParams.toDate,
                    Trekker: this.serverParams.trekker,
                    Gender: this.serverParams.gender,
                    AgeGroup: this.serverParams.ageGroup,
                    CountryName: this.serverParams.countryName,
                    TrekkingRoute: this.serverParams.trekkingRoute
                }


                await axios({
                    url: 'Trekking/GetRestrictedAreasCountReport',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'RestrictedAreasReport.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                });
            },

            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            async loadFilterListItems() {

                this.snackbar.isLoading = true;
                let param = {}
                param = {
                    FromDate: this.serverParams.fromDate,
                    ToDate: this.serverParams.toDate,
                    Trekker: this.serverParams.trekker,
                    Gender: this.serverParams.gender,
                    AgeGroup: this.serverParams.ageGroup,
                    CountryName: this.serverParams.countryName,
                    TrekkingRoute: this.serverParams.trekkingRoute,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                };

                await axios({
                    url: 'Trekking/GetRestrictedAreasList',
                    method: 'POST',
                    data: param
                }).then((response) => {

                    this.isLoading = true
                    this.responseData = response.data;

                    this.responseData.forEach(t => {

                        let trekker =
                        {
                            id: t.trekker,
                            value: t.trekker
                        }

                        let trekkingRoute =
                        {
                            id: t.trekkingRoute,
                            value: t.trekkingRoute
                        }

                        let countryName =
                        {
                            id: t.countryName,
                            value: t.countryName
                        }

                        this.Trekkers.push(trekker)
                        this.TrekkingRoutes.push(trekkingRoute)
                        this.CountryNames.push(countryName)
                    })

                    this.isLoading = false
                })
                    .catch(err => {
                        this.isLoading = false
                        console.log(err)
                    });
            }

            ,
            async loadItems() {
                this.isLoading = true;
                this.snackbar.isLoading = true;
                    let param = {}
                        param = {
                            FromDate: this.serverParams.fromDate,
                            ToDate: this.serverParams.toDate,
                            Trekker: this.serverParams.trekker,
                            Gender: this.serverParams.gender,
                            AgeGroup: this.serverParams.ageGroup,
                            CountryName: this.serverParams.countryName,
                            TrekkingRoute: this.serverParams.trekkingRoute,
                            OrderType: this.serverParams.sort[0].type,
                            OrderBy: this.serverParams.sort[0].field,
                            PageSize: this.serverParams.perPage,
                            PageNo: this.serverParams.page,
                        };

                await axios({
                    url: 'Trekking/GetRestrictedAreasCountList',
                    method: 'POST',
                    data: param
                }).then((response) => {
                    this.rows = response.data;
                    this.isLoading = false;
                }).catch(err => {
                    this.isLoading = false
                    console.log(err)
                });
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage == -1 ? this.totalRecords : params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
        },
        mounted() {
            this.loadItems();
            this.loadFilterListItems();
        },
        data() {
            return {
                AgeGroup: [
                    {
                        id: "0-15", value: "0-15"
                    },
                    {
                        id: "16-30", value: "16-30"
                    },
                    {
                        id: "31-45", value: "31-45"
                    },
                    {
                        id: "46-60", value: "46-60"
                    },
                    {
                        id: "60+", value: "60+"
                    }
                ],
                Gender: [
                    {
                        id: this.$t('male'), value: "male"
                    },
                    {
                        id: this.$t('female'), value: "female"
                    }
                ],
                Trekkers: [],
                TrekkingRoutes: [],
                CountryNames: [],
                excelData: {
                    loading: false,
                    json_fields: {},
                    json_data: [],
                    json_meta: [
                        [
                            {
                                key: "charset",
                                value: "utf-8",
                            },
                        ],
                    ],
                },
                activeComponent: "",
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                responseData: [],
                rows: [],
                columns: [
                    {
                        label: 'countryName',
                        field: 'countryName'
                    },
                    {
                        label: 'trekkingRoute',
                        field: 'trekkingRoute'
                    },
                    {
                        label: 'gender',
                        field: 'gender'
                    },
                    {
                        label: 'gender',
                        field: 'genderCount'
                    },
                ],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "TrekkingRoute",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    trekker: "",
                    gender: "",
                    ageGroup: "",
                    countryName: "",
                    trekkingRoute: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                }
            }
        }
    }
</script>
